import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestInterceptor} from './core/interceptors/request.interceptor';
import {ErrorsHandler} from './core/interceptors/error.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {GlobalService} from './core/services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {MESSAGE_FORMAT_CONFIG} from 'ngx-translate-messageformat-compiler';
import {appLanguagesInitializerFactory} from './shared/utils/ngx-translate';
import {OAuthRedirectComponent} from './domains/o-auth-redirect/o-auth-redirect.component';
import {RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha-2';
import {environment} from '../environments/environment';
import {FeatureFlagsService} from './libraries/feature-flags/feature-flags.service';
import {AuthService} from './store/auth';
import {eventeeInitializerFactory} from './shared/utils/eventee-initializer-factory';
import {Router} from '@angular/router';
import {I18nModule} from "./libraries/i18n/i18n.module";
import {GleapService} from "./libraries/gleap/gleap.service";
import {ProductFruitsService} from "./shared/services/product-fruits.service";

@NgModule({
	declarations: [
		AppComponent,
		OAuthRedirectComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		RecaptchaModule,
		I18nModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-center',
			preventDuplicates: true,
			resetTimeoutOnDuplicate: true
		}), // This library doesn't allow lazy loading ( https://github.com/scttcper/ngx-toastr/issues/400 )
		LoadingBarRouterModule,
		LoadingBarHttpClientModule,
		LoadingBarModule
	],
	providers: [
		GlobalService,
		[{provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true}],
		[{provide: ErrorHandler, useClass: ErrorsHandler}],
		{provide: MESSAGE_FORMAT_CONFIG, useValue: {locales: ['en', 'cs', 'de', 'es']}},
		{
			provide: APP_INITIALIZER,
			useFactory: appLanguagesInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: eventeeInitializerFactory,
			deps: [AuthService, FeatureFlagsService, Router, GleapService, ProductFruitsService],
			multi: true
		},
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: {siteKey: environment.recaptchaKey} as RecaptchaSettings,
		},
		{
			provide: RECAPTCHA_LANGUAGE,
			useFactory: (locale: string) => locale,
			deps: [LOCALE_ID],
		},
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
	}
}
