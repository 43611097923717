import {Injectable} from "@angular/core";
import {User} from "../../core/models/user";

@Injectable({
	providedIn: 'root'
})
export class HubspotService {
	public identifyUser(user: User) {
		const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
		_hsq.push([
			'identify',
			{
				email: user.email
			},
		]);
	}
}